<template>
  <div id="bbForm">
    <van-form ref="form" class="form" style="margin-bottom:65px">
      <van-cell-group title="审核信息">
        <audit ref="disAudit" :app-params="{ coreKey: $route.query.id,  mkCode: 'ZHCP',coreCode:'ATTENDANCE', status:$route.query.status }" />
      </van-cell-group>
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <custom-select ref="xn" required v-model="form.xn" placeholder="请选择学年" label="学年" :columns="dict.xj_nj" />
        <custom-select ref="xq" required v-model="form.xq" placeholder="请选择学期" label="学期" :columns="dict.xj_xqgl" />
        <custom-select ref="applayType" required v-model="form.applayType" placeholder="请选择申请分类" labelKey="name" valueKey="id" label="申请分类" :columns=" option1" />
        <van-field v-model="form.violateNum" placeholder="请选择事项数" v-if="byNumShow" label="事项数" :required="true" :rules="[{ required: true  } ]">]
          <template #input>
            <van-stepper v-model="form.violateNum" min="1" max="20" />
          </template>
        </van-field>
        <custom-select ref="applayType2" required v-if=" option2 && option2.length > 0 " v-model="form.applayType2" placeholder="请选择等级" label="级别" labelKey="name" valueKey="id" :columns="option2" />
        <custom-select ref="applayType3" required v-if=" option3 && option3.length > 0 " v-model="form.applayType3" placeholder="请选择等级" label="类型" labelKey="name" valueKey="id" :columns="option3" />
        <van-field v-model="form.score" label="分值" readonly>
        </van-field>
        <custom-select ref="shr" required v-model="form.shr" placeholder="请选择审核人" labelKey="name" valueKey="id" label="审核人" :columns="auditByInfo" />
      </van-cell-group>

      <!-- 负责学生-->
      <van-cell-group title="学生信息">
        <van-field v-model="form.stuName" label="姓名" readonly placeholder="点击选择学生" />
        <van-field v-model="form.stuNo" label="学号" readonly />
        <van-field v-model="form.facultyName" label="院系" readonly />
        <van-field v-model="form.specName" label="专业" readonly />
        <van-field v-model="form.className" label="班级" readonly />
        <van-field v-model="form.explain" rows="3" :required="true" :rules="[{ required: true  } ]" class="textarea-style" placeholder="请填写申请说明" arrow-direction="" label="申请说明" type="textarea" maxlength="300" />
      </van-cell-group>
      <!-- 附件信息 -->
      <van-cell-group title="附件">
        <file-upload v-if="refsh" accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.gif,.jpeg" :preview="true" v-model="fileList" miz="STU_PUNISH_RESOLVE" biz="storage" :multiple="1" v-on:uploadResponse="uploadResponse" list-type="list" :auto-upload="true" :max-size="1">
          <span slot="tip" class="upload-tips">
            支持上传任意文件,文件最大1M
          </span>
        </file-upload>
      </van-cell-group>
    </van-form>
  </div>
</template>
<script>
import crudEvaluationAddScore from '@/api/modules/evaluation/evaluationAddScore'
import fileUpload from "@/components/FileUpload"
import audit from "@/view/modules/common/auditAddScore";
import { getTermYear, getTerm } from '@/utils/index'
import crudEvaluationConfig from '@/api/modules/evaluation/evaluationConfig'


export default {
  name: "EvaluationActivityForm",
  dicts: ['evaluation_activity_type', 'xj_nj', 'xj_xqgl', 'xj_shzt'],
  components: { fileUpload, audit },
  data() {
    return {
      form: {
        shr: null, xn: null, xq: null, id: null, violateNum: null,
        stuInfo: [{
          stuNo: "",
          stuName: "",
          className: "",
          classNo: "",
          facultyName: "",
          facultyNo: "",
          specName: "",
          specNo: "",
          grade: "",
          conslrStaffNo: "",
          conslrStaffName: "",
          fileId: "",
        }],
        violateNum: null,
        applayType: null,
        applayType2: null,
        applayType3: null,
        explain: null,
        fileId: null,
        stuNo: null,
        stuName: null,
        grade: null,
        facultyNo: null,
        facultyName: null,
        specNo: null,
        specName: null,
        classNo: null,
        className: null,
        score: null,
        type: 1
      },
      option1: [],
      option2: [],
      option3: [],
      option4: [],
      fresh: true,
      byNumShow: false,
      activeNames: [0],
      activeNames2: [0],
      isFamilyAdd: false,
      familyInfoShow: false,
      editIndex: 0,
      loading: false,
      auditByInfo: [],
      search: "",
      stuData: [],
      refsh: true,
      fileList: [],
      byNumShow: false,
      search: "",
      customRuleFileds: ["xn", "xq", "applayType", "shr"]
    }
  },
  watch: {
    "dict.xj_xqgl": {
      deep: false,
      handler(newval, oldval) {
        if (newval.length > 0) {
          this.getTermAndYear(newval);
        }
      },
    },
  },
  async created() {
    // 或者审核人最小权限
    crudEvaluationConfig.getAuditUserPage().then((res) => {
      this.auditByInfo = res
    })
    crudEvaluationConfig.getConfigNoPage({ type: 2 }).then((res) => {
      if (res) {
        this.option1 = res.map((e) => {
          e['childs'] = e.children
          e.children = undefined
          return e;
        });
      }
    })
    if (this.$route.query && this.$route.query.id) {
      await crudEvaluationAddScore.findById(this.$route.query.id).then((res) => {
        this.form = { ...res }
        if (res.fileId) {
          this.fileList = [res.fileId]
        }

        let data = res
        this.byNumShow = data.type1.byNum
        this.score = data.type1.value


        // 处理组件回显
        if (data.applayType2) {
          this.score = data.type2.value
          this.option2 = data.type1.children.map((e) => {
            e['childs'] = e.children
            e.children = undefined
            return e;
          });

        } else {
          this.form.applayType2 = null
        }
        if (data.applayType3) {
          this.score = data.type3.value
          this.option3 = data.type2.children.map((e) => {
            e['childs'] = e.children
            e.children = undefined
            return e;
          });
        } else {
          this.form.applayType3 = null
        }
        this.refsh = false
        this.$nextTick((e) => {
          this.refsh = true
        });

      })

    }
  },
  methods: {
    uploadResponse() { },
    getTermAndYear(xj_xqgl) {
      if (xj_xqgl && xj_xqgl.length > 0) {
        let termYear = getTermYear(
          xj_xqgl,
          (new Date().getMonth() + 1).toString()
        )
        let term = getTerm(
          xj_xqgl,
          (new Date().getMonth() + 1).toString()
        )
        this.form.xn = termYear
        this.form.xq = term.xqCode
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#bbForm {
  .form {
    ::v-deep.radio-other-info {
      .van-cell {
        .van-field__label {
          width: 11.2em;
        }
      }
    }

    .van-field__label {
      width: 110px;
    }
    .van-cell {
      font-size: 15px;
      line-height: 32px;
      height: 50px;
    }
    .textarea-style {
      height: 100%;
    }
  }

  .wj-title {
    text-align: center;
    line-height: 55px;
    font-size: 17px;
    font-weight: bold;
    height: 40px;
    background-color: #fff;
  }
  .wj-search {
  }
  .stu-card {
    margin-top: 18px;
  }
  .wj-body {
    overflow-y: scroll;
    height: calc(80vh - 100px);
    padding: 0 18px;
  }
}
</style>